import cn from 'classnames';

import {ButtonSizeEnum, LinkDisplayEnum} from '@/enums';
import BaseButton from '@/components/shared/BaseButton/BaseButton';
import {
  type ButtonProps,
  ButtonTheme,
  ButtonVariant,
} from '@/components/brochureV2/primitive/Button/types';

import {buttonClasses, linkClasses} from '../styleConstants';

type LinkProps = ButtonProps & {
  arrowAnimation?: boolean;
  className?: string;
  inline?: boolean;
  visually?: LinkDisplayEnum;
  target?: string;
};

/**
 * @deprecated
 */
export default function Link({
  arrowAnimation = true,
  className,
  children,
  inline = false,
  size = ButtonSizeEnum.Medium,
  theme = ButtonTheme.Light,
  variant = ButtonVariant.Primary,
  visually = LinkDisplayEnum.Link,
  ...buttonProps
}: LinkProps) {
  const {themeClasses, textSizeClasses, baseLinkClasses} = linkClasses;
  const {buttonBaseClasses, paddedVariantClasses, variantThemeClasses} =
    buttonClasses;
  const classes =
    visually === LinkDisplayEnum.Link
      ? cn(
          {'arrow-animation': !inline && arrowAnimation},
          className,
          baseLinkClasses,
          themeClasses[theme],
          textSizeClasses[size],
        )
      : cn(
          buttonBaseClasses,
          paddedVariantClasses,
          variantThemeClasses[`${variant}-${theme}`],
          {
            'text-body-sm px-[1.375rem] py-[0.875rem]':
              size === ButtonSizeEnum.Small,
          },
          {
            'text-body-base px-[1.75rem] py-[1.1875rem]':
              size === ButtonSizeEnum.Large || size === ButtonSizeEnum.Medium,
          },
          className,
        );

  return (
    <BaseButton {...buttonProps} type="link" className={classes}>
      {children}
    </BaseButton>
  );
}
